import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Контакти | Японська Кулінарна Академія: Смак Східного Сонця
			</title>
			<meta name={"description"} content={"Смак Східного Сонця – Гастрономічні Подорожі до Світу Японської Кухні"} />
			<meta property={"og:title"} content={"Контакти | Японська Кулінарна Академія: Смак Східного Сонця"} />
			<meta property={"og:description"} content={"Смак Східного Сонця – Гастрономічні Подорожі до Світу Японської Кухні"} />
			<link rel={"shortcut icon"} href={"https://ritas-flet.net/img/5900160.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ritas-flet.net/img/5900160.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ritas-flet.net/img/5900160.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ritas-flet.net/img/5900160.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL1" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Контакти
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Приєднайся до нас у подорожі в світ східної кулінарії. Поставити запитання та записатися на курс можна за наступними контактами:
				</Text>
				<Link
					href="mailto:blank?info@ritas-flet.net"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@ritas-flet.net
				</Link>
				<Link
					href="tel:1234567899"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					(073) 055-32-10
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					проспект Миколи Бажана, 8, Київ, 02000{"\n\n"}
				</Text>
				<SocialMedia facebook="https://www.facebook.com/" twitter="https://twitter.com/" instagram="https://instagram.com/" margin="0px 0px 0px 0px">
					<Override
						slot="link"
						border-radius="50%"
						color="--grey"
						hover-color="--light"
						background="#191E22"
						hover-background="--color-primary"
						margin="0 5px 0 5px"
						padding="5x 5px 5px 5px"
						width="48px"
						height="48px"
						align-items="center"
						display="flex"
						justify-content="center"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
					/>
					<Override
						slot="icon"
						color="--light"
						padding="7px 7px 7px 7px"
						border-width="0px"
						size="38px"
						border-radius="50px"
					/>
				</SocialMedia>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523ace1e2dd3a0022204083"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});